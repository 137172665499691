import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "break-words typography-body-2-bold" };
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { key: 2 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_i18n_t = _resolveComponent("i18n-t");
    const _component_OrConfirmV3 = _resolveComponent("OrConfirmV3");
    return (_openBlock(), _createBlock(_component_OrConfirmV3, {
        ref: "confirm",
        "data-test": "delete-contacts-modal",
        "title-text": _ctx.$t('common.deletePermanently'),
        loading: _ctx.isLoading,
        "auto-close-on-confirm": false,
        "confirm-button-text": _ctx.$t('common.delete'),
        "deny-button-text": _ctx.$t('common.cancel'),
        "confirmation-text": _ctx.isDeletingAll ? _ctx.contactBookName : null,
        "text-confirmation-label": _ctx.$t('contactBooks.deleteModal.contactBookName'),
        "text-confirmation-error": _ctx.$t('contactBooks.deleteModal.namesDoNotMatch'),
        onConfirm: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('accept')))
    }, {
        default: _withCtx(() => [
            (_ctx.isDeletingAll)
                ? (_openBlock(), _createBlock(_component_i18n_t, {
                    key: 0,
                    keypath: "contacts.deleteModal.permanentlyText",
                    scope: "global",
                    tag: "span"
                }, {
                    contactBook: _withCtx(() => [
                        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.contactBookName), 1)
                    ]),
                    _: 1
                }))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$tc('contacts.deleteModal.text', _ctx.contactsCount)), 1)),
            (_ctx.isDeletingAll)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('contactBooks.deleteModal.enterNameToConfirm')), 1))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }, 8, ["title-text", "loading", "confirm-button-text", "deny-button-text", "confirmation-text", "text-confirmation-label", "text-confirmation-error"]));
}
